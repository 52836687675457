// libs
import styled from "styled-components";
// theme
import { typography, colors } from "src/theme";
// const
import devices from "src/constants/media";

export const Wrapper = styled.div`
  position: relative;
`;
export const Content = styled.div`
  position: relative;
  padding: 6px 24px 113px;

  @media ${devices.laptop} {
    max-width: 871px;
    padding: 182px 0 332px;
    margin: 0 auto;
  }
`;
export const Title = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.white};

  @media ${devices.tablet} {
    font-size: 36px;
    line-height: 44px;
    padding-right: 280px;
  }
`;
export const Text = styled.p`
  font-family: ${typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.mainLight};
  margin: 12px 0 40px;

  @media ${devices.tablet} {
    font-size: 20px;
    line-height: 28px;
    margin: 8px 0 96px;
  }
`;
export const ImageContainer = styled.div`
  display: none;

  @media ${devices.laptop} {
    display: block;
    position: absolute;
    top: 20px;
    right: -20%;
    z-index: 1;
  }
`;
export const MainInputContainer = styled.div`
  position: relative;
`;
export const Doodle = styled.div`
  content: url("/doodle/doodle-double-line.svg");
  position: absolute;
  top: 64px;
  right: -90px;
  z-index: 1;
`;
export const Waves = styled.div`
  position: absolute;
  width: 100%;
  height: 888px;
  top: 300px;
  background-image: url("/background/WavesUp.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
