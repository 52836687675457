import { request, gql } from "graphql-request";

import React from "react";
// const
import {
  FOOTER,
  HEADER,
  REGISTER_YOUR_PET_BLOCK,
  STORES_RATING_BLOCK,
  LIST_YOUR_CLINIC_BLOCK,
  WHY_LIST_YOUR_CLINIC_BLOCK,
  MORE_PETS_APP_BLOCK,
  CUSTOMER_ENGAGEMENT_BLOCK,
  GET_THE_APP_BLOCK,
  CORE,
  DEMO_BLOCK,
  HOW_IT_WORKS_BLOCK,
  NEXT_WEBINAR_BLOCK,
  WHY_PETS_APP_BLOCK,
  HOW_PETS_APP_STACKS_UP_BLOCK,
  USED_BY_BLOCK,
  WHAT_HAVE_TO_SAY,
  YOUR_CLINICS_APP_BLOCK,
  META,
} from "src/constants/dynamic";
// redux
import { initializeStore } from "src/redux/store";
import { bulkSetState } from "src/redux/slices";

const query = gql`
  query getAllPage($locale: String!) {
    metaTags(locale: $locale) {
      id
      route
      meta_tags {
        keywords
        title
      }
    }
    footer(locale: $locale) {
      Company_info {
        ... on ComponentTextText {
          id
          text
        }
      }
      Resources {
        ... on ComponentLinksLink {
          id
          new_tab
          title
          url
        }
      }
      Company {
        ... on ComponentLinksLink {
          id
          new_tab
          title
          url
        }
      }
      Social {
        ... on ComponentLinksLinkWithIcon {
          id
          __typename
          url
          icon {
            id
            name
            alternativeText
            caption
            width
            height
            formats
            hash
            ext
            mime
            size
            url
            previewUrl
            provider
            provider_metadata
            created_at
            updated_at
          }
          title
          new_tab
        }
      }
    }

    header(locale: $locale) {
      header_links {
        ... on ComponentLinksLink {
          id
          url
          title
          new_tab
          type
        }
      }
      sign_in_dropdown {
        ... on ComponentLinksLink {
          id
          url
          title
          new_tab
          type
        }
      }
    }

    registerYourPet(locale: $locale) {
      title
      text
      buttons {
        __typename
        ... on ComponentButtonsButton {
          id
          title
          url
          new_tab
          type
        }
      }
    }

    storesRatingBlock(locale: $locale) {
      text
      appstore_score
      applestore_count
      google_count
      google_score
    }

    listYourClinic(locale: $locale) {
      title
      text
    }

    whyListYourClinicOnPetsApp(locale: $locale) {
      title
      services {
        id
        title
        text
        image {
          url
        }
      }
    }

    evenMoreToPetsApp(locale: $locale) {
      title

      text_blocks {
        id
        text
        title
        image {
          name
          url
        }
        buttons {
          id
        }
      }

      buttons {
        ... on ComponentButtonsButton {
          type
          id
          title
          url
        }
      }
    }

    wantToImproveCustomerEngagement(locale: $locale) {
      title
      text

      buttons {
        url
        title
        type
        new_tab
      }

      image {
        url
      }
    }

    getTheAppBlock(locale: $locale) {
      title
      text
    }

    core(locale: $locale) {
      google_url
      apple_url
    }

    findOutHowWeCanHelp(locale: $locale) {
      title
      text
      sub_title
      hubspot_url
    }

    nextWebinar(locale: $locale) {
      title
      text
      date
      url
      image {
        id
        url
      }
      links {
        id
        url
        type
        title
      }
    }

    howItWorksBlock(locale: $locale) {
      id
      created_at
      title
      description
      url
      buttons {
        ... on ComponentButtonsButton {
          type
          id
          title
          url
        }
      }
    }

    whyPetsapp(locale: $locale) {
      blocks {
        ... on ComponentBlocksImageTextBlock {
          id
          title
          text
          text_with_icon {
            text
            icon {
              url
            }
          }
          buttons {
            id
            type
            title
            url
          }
          image {
            url
          }
        }
      }
    }

    usedBy(locale: $locale) {
      title
      logos {
        ... on ComponentLinksImageLink {
          id
          image {
            name
            url
          }
          url
        }
      }
      action_button {
        url
        title
        new_tab
        type
      }
      image {
        url
      }
    }

    howPetsAppStacksUp(locale: $locale) {
      avg_rating_text
      avg_rating
      revenue
      revenue_text
      revenue_symbol
      care_interactions
      care_interactions_text
      professionals
      professionals_text
      image {
        url
      }
      locale
    }

    whatClinicsHaveToSay(locale: $locale) {
      title
      avatar {
        url
      }
      clinic_reviews {
        id
        text
        person
      }
    }

    yourClinicsApp(locale: $locale) {
      title
      text
      videos {
        ... on ComponentVideosTabsVideo {
          tab_name
          cover {
            url
          }
          id
          youtube_url
          video {
            url
          }
        }
      }
    }
  }
`;

const useAllPage = async (locale: string) => {
  const reduxStore = initializeStore();
  const { dispatch } = reduxStore;

  try {
    const {
      metaTags,
      footer,
      header,
      registerYourPet,
      storesRatingBlock,
      listYourClinic,
      whyListYourClinicOnPetsApp,
      evenMoreToPetsApp,
      wantToImproveCustomerEngagement,
      getTheAppBlock,
      core,
      findOutHowWeCanHelp,
      nextWebinar,
      howItWorksBlock,
      whyPetsapp,
      usedBy,
      howPetsAppStacksUp,
      whatClinicsHaveToSay,
      yourClinicsApp,
    } = await request(process.env.API_URL + "/graphql", query, { locale });

    dispatch(
      bulkSetState({
        [META]: metaTags,
        [FOOTER]: footer,
        [HEADER]: header,
        [REGISTER_YOUR_PET_BLOCK]: registerYourPet,
        [STORES_RATING_BLOCK]: storesRatingBlock,
        [LIST_YOUR_CLINIC_BLOCK]: listYourClinic,
        [WHY_LIST_YOUR_CLINIC_BLOCK]: whyListYourClinicOnPetsApp,
        [MORE_PETS_APP_BLOCK]: evenMoreToPetsApp,
        [CUSTOMER_ENGAGEMENT_BLOCK]: wantToImproveCustomerEngagement,
        [GET_THE_APP_BLOCK]: getTheAppBlock,
        [CORE]: core,
        [HOW_IT_WORKS_BLOCK]: howItWorksBlock,
        [DEMO_BLOCK]: findOutHowWeCanHelp,
        [NEXT_WEBINAR_BLOCK]: nextWebinar,
        [WHY_PETS_APP_BLOCK]: whyPetsapp,
        [HOW_PETS_APP_STACKS_UP_BLOCK]: howPetsAppStacksUp,
        [USED_BY_BLOCK]: usedBy,
        [WHAT_HAVE_TO_SAY]: whatClinicsHaveToSay,
        [YOUR_CLINICS_APP_BLOCK]: yourClinicsApp,
      }),
    );
  } catch (error) {
    console.log("useAllPage.error: ", error);
  } finally {
    return reduxStore.getState();
  }
};

export default useAllPage;
