import React, { FC } from "react";
// libs
import NextImage from "next/image";
// components
import MainInput from "src/components/containers/home/sections/MainInput";
// theme
import {
  Wrapper,
  Content,
  Title,
  Text,
  ImageContainer,
  MainInputContainer,
  Doodle,
  Waves,
} from "./NoResults.styles";

interface INoResults {
  title: string;
  subTitle: string;
}

const NoResults: FC<INoResults> = ({ title, subTitle }) => {
  return (
    <Wrapper>
      <Content>
        <Title>{title}</Title>
        <Text>{subTitle}</Text>
        <ImageContainer>
          <NextImage width={465} height={353} src="/icons/paper-plane.png" />
        </ImageContainer>
        <MainInputContainer>
          <MainInput placeholder="For any species" />
          <Doodle />
        </MainInputContainer>
      </Content>
      <Waves />
    </Wrapper>
  );
};

export default NoResults;
