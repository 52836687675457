import { NextPage } from "next";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import styled from "styled-components";
import useAllPage from "../../../hooks/dynamic/allPage";
import CustomHead from "../../ui/CustomHead";
import Layout from "../../../layout";
import NoResults from "../noResults/NoResults";
import { colors } from "../../../theme/colors";

interface IProps {
  title: string;
  subTitle: string;
  code: number;
}

const Wrapper = styled.div`
  background-color: ${colors.main};
  overflow: hidden;
`;

const ErrorPage: NextPage<IProps> = ({ title, subTitle, code }) => {
  const { locale } = useRouter();
  useEffect(() => {
    useAllPage(locale as string);
  }, []);
  return (
    <>
      <CustomHead title={`${code}: ${title}`} />
      <Wrapper>
        <Layout headerProps={{ styles: { width: "1096px" } }}>
          <NoResults title={title} subTitle={subTitle} />
        </Layout>
      </Wrapper>
    </>
  );
};

export default ErrorPage;
